<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-account-group-outline</v-icon>
        Usuarios
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title class="align-end">
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="primary"
              class="white--text mr-2"
              @click="excelUsuariosNaoCadastrados"
            >
              <v-icon left> mdi-file-excel-outline</v-icon>
              não Cadastrados
            </v-btn>

            <v-btn
              :to="{ path: 'usuarios/importar' }"
              align="center"
              color="primary"
              class="white--text mr-2"
            >
              <v-icon left> mdi-file-excel-outline</v-icon>
              Importar Usuários
            </v-btn>
            <v-btn
              :to="{ path: 'usuarios/adicionar' }"
              align="center"
              color="xbColor"
              class="white--text"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-card-title>
          <div class="d-flex my-4">
            <!-- Select Roles -->
            <v-select
              v-model="rolesFilter"
              :items="roles_lista"
              label="Tipo Usuário"
              multiple
              hide-details
              dense
              background-color="white"
              style="max-width: 350px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-start">
                  <v-sheet
                    width="10px"
                    height="10px"
                    :color="item.color"
                    style="border-radius: 3px"
                    class="mr-2"
                  >
                  </v-sheet>
                  {{ item.text }}
                </div>
              </template>

              <template v-slot:selection="{ item, index }">
                <div class="d-flex align-center justify-start">
                  <v-sheet
                    width="10px"
                    height="10px"
                    :color="item.color"
                    style="border-radius: 3px"
                    class="mr-2"
                  >
                  </v-sheet>
                  <span class="text-caption">{{ item.text }}</span>
                </div>
                <v-divider
                  v-if="index < rolesFilter.length - 1"
                  class="mx-2 mt-8 mt-sm-0"
                  vertical
                ></v-divider>
              </template>
            </v-select>

            <!-- Dispositivo -->
            <v-select
              v-model="selectedDispositivo"
              :items="dispositivos"
              label="Dispositivos"
              hide-details
              clearable
              dense
              style="max-width: 250px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
            </v-select>

            <!-- Cadastro -->
            <v-select
              v-model="selectedCadastro"
              :items="cadastro"
              label="Cadastro"
              hide-details
              clearable
              dense
              style="max-width: 200px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
            </v-select>

            <!-- Checkin -->
            <v-select
              v-model="selectedCheckin"
              :items="checkin"
              label="Checkin"
              hide-details
              clearable
              dense
              style="max-width: 200px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
            </v-select>

            <!-- Vip -->
            <v-select
              v-model="selectedVip"
              :items="vip"
              label="Vip"
              hide-details
              clearable
              dense
              style="max-width: 100px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
            </v-select>

            <!-- Maestria -->
            <v-select
              v-model="selectedMaestria"
              :items="maestria"
              label="Maestria"
              hide-details
              clearable
              dense
              style="max-width: 100px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
            </v-select>

            <!-- XDiamond -->
            <v-select
              v-model="selectedXDiamond"
              :items="xdiamond"
              label="XDiamond"
              hide-details
              clearable
              dense
              style="max-width: 100px"
              class="ml-0 ml-sm-3 mt-sm-0"
            >
            </v-select>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="usuarios"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
              @click:row="goToUsuario"
            >
              <!-- Role Color -->
              <template v-slot:item.role_description="{ item }">
                <div class="d-flex align-center">
                  <v-sheet
                    width="10px"
                    height="10px"
                    :color="item.role_color"
                    style="border-radius: 3px"
                    class="mr-2"
                  >
                  </v-sheet>
                </div>
              </template>

              <!-- Nome -->
              <template v-slot:item.nome="{ item }">
                <div class="d-flex align-center justify-space-between">
                  {{ item.nome }}

                  <v-chip
                    v-if="item.status != 1"
                    x-small
                    color="error"
                    label
                    text-color="white"
                    class="font-weight-bold"
                  >
                    <span v-if="item.status == 0">DESATIVADO</span>
                    <span v-if="item.status == -1">ESTORNADO</span>
                  </v-chip>
                </div>
              </template>

              <!-- Created At -->
              <template v-slot:item.created_at="{ item }">
                {{ item.created_at | dateFormat("dd/MM/yyyy") }}
              </template>

              <!-- User Agent -->
              <template v-slot:item.user_agent="{ item }">
                {{ item.user_agent | excerpt(20) }}
              </template>

              <!-- Celular -->
              <template v-slot:item.celular="{ item }">
                <div class="d-flex align-center justify-start">
                  <span>{{ item.celular }}</span>

                  <v-btn x-small icon class="ml-1" v-if="item.celular">
                    <v-icon
                      small
                      @click.stop="goToWhats(item.celular)"
                      color="green"
                    >
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>
                </div>
              </template>

              <!-- Cadastro -->
              <template v-slot:item.cadastro="{ item }">
                <v-btn @click.stop="toggleCadastro(item)" icon>
                  <v-icon
                    :color="item.cadastro ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.cadastro
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>

              <!-- Checkin -->
              <template v-slot:item.checkin="{ item }">
                <v-btn @click.stop="toggleCheckin(item)" icon>
                  <v-icon
                    :color="item.checkin ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.checkin
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>

              <!-- Vip -->
              <template v-slot:item.vip="{ item }">
                <v-btn @click.stop="toggleVip(item)" icon>
                  <v-icon
                    :color="item.vip ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.vip
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>

              <!-- Tes -->
              <template v-slot:item.tes="{ item }">
                <v-btn @click.stop="toggleTes(item)" icon>
                  <v-icon
                    :color="item.tes ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.tes
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>

              <!-- Maestria -->
              <template v-slot:item.maestria="{ item }">
                <v-btn @click.stop="toggleMaestria(item)" icon>
                  <v-icon
                    :color="item.maestria ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.maestria
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>

              <!-- XDiamond -->
              <template v-slot:item.xdiamond="{ item }">
                <v-btn @click.stop="toggleXDiamond(item)" icon>
                  <v-icon
                    :color="item.xdiamond ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.xdiamond
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <!-- desativar usuario -->
              <template v-slot:item.status="{ item }">
                <v-btn @click.stop="toggleDesativar(item)" icon>
                  <v-icon
                    :color="item.status ? 'green darken-2' : 'grey lighten-1'"
                  >
                    {{
                      item.status
                        ? "mdi-checkbox-marked-circle"
                        : "mdi-close-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  all as fetchUsuarios,
  dispositivosUtilizados,
  putUsuario,
  deactivateUser,
} from "@/api/admin/usuarios.js";
import { excelDownload } from "@/helpers/excel.js";
import { format } from "date-fns";

export default {
  name: "usuarios",

  data() {
    return {
      loading: true,
      loadingDispositivos: false,
      usuarios: [],
      search: "",
      rolesFilter: [1, 2, 3, 4],
      dispositivos: [],
      selectedDispositivo: null,
      selectedCadastro: null,
      selectedCheckin: null,
      selectedVip: null,
      selectedMaestria: null,
      selectedXDiamond: null,
    };
  },

  components: {},

  computed: {
    headers() {
      return [
        {
          text: "",
          width: "40px",
          value: "role_description",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "documento",
          value: "documento",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "celular",
          width: "120px",
          sortable: false,
          value: "celular",
        },
        {
          text: "Cadastrado",
          align: "center",
          width: "80px",
          sortable: false,
          value: "cadastro",
        },
        {
          text: "Checkin",
          value: "checkin",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "Vip",
          value: "vip",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "Maestria",
          value: "maestria",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "Tes",
          value: "tes",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "XDiamond",
          value: "xdiamond",
          width: "80px",
          sortable: false,
          align: "center",
        },
        {
          text: "Ativo",
          value: "status",
          width: "80px",
          sortable: false,
          align: "center",
        },
      ];
    },
    roles_lista() {
      return [
        {
          text: "Admin",
          value: 1,
          color: "#292828",
        },
        {
          text: "Participante",
          value: 2,
          color: "#FEB60C",
        },
        {
          text: "Staff",
          value: 3,
          color: "#72DD9F",
        },
        {
          text: "Mercado",
          value: 4,
          color: "#36a7c9",
        },
      ];
    },
    cadastro() {
      return [
        {
          text: "Cadastrado",
          value: 1,
        },
        {
          text: "Não Cadastrado",
          value: 2,
        },
      ];
    },
    checkin() {
      return [
        {
          text: "Fez Checkin",
          value: 1,
        },
        {
          text: "Não Fez Checkin",
          value: 2,
        },
      ];
    },
    vip() {
      return [
        {
          text: "Sim",
          value: true,
        },
        {
          text: "Nao",
          value: false,
        },
      ];
    },
    maestria() {
      return [
        {
          text: "Sim",
          value: true,
        },
        {
          text: "Nao",
          value: false,
        },
      ];
    },
    xdiamond() {
      return [
        {
          text: "Sim",
          value: true,
        },
        {
          text: "Nao",
          value: false,
        },
      ];
    },

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }

      if (this.rolesFilter.length) {
        queryString += `&roles_filter=${this.rolesFilter.join()}`;
      }
      if (this.selectedDispositivo) {
        queryString += `&user_agent=${this.selectedDispositivo}`;
      }
      if (this.selectedCadastro) {
        queryString += `&cadastro=${this.selectedCadastro}`;
      }
      if (this.selectedCheckin) {
        queryString += `&checkin=${this.selectedCheckin}`;
      }
      if (this.selectedVip != null) {
        queryString += `&vip=${this.selectedVip}`;
      }
      if (this.selectedMaestria != null) {
        queryString += `&maestria=${this.selectedMaestria}`;
      }
      if (this.selectedXDiamond != null) {
        queryString += `&xdiamond=${this.selectedXDiamond}`;
      }
      return `?${queryString}`;
    },
  },

  watch: {
    url: {
      handler() {
        this.getUsuarios();
      },
      immediate: true,
    },
  },

  methods: {
    goToWhats(telefone) {
      let url = "https://api.whatsapp.com/send?phone=";

      let telefone_sanatizado = telefone.replace(/\D/g, "");

      if (telefone_sanatizado.substr(0, 3) == "595") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else if (telefone_sanatizado.substr(0, 2) == "55") {
        url = url + telefone_sanatizado;
        window.open(url, "_blank");
      } else {
        url = url + "55" + telefone_sanatizado;
        window.open(url, "_blank");
      }
    },
    goToUsuario(item) {
      this.$router.push({ path: `/usuarios/${item.id}` });
    },

    getUsuarios() {
      this.loading = true;
      fetchUsuarios(this.url)
        .then((response) => {
          this.usuarios = response.map((item) => {
            let role = this.roles_lista.find(
              (r) => r.value === item.role_name.id
            );
            item.role_description = role.text;
            item.role_color = role.color;

            return item;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    excelUsuariosNaoCadastrados() {
      const tHeader = ["NOME", "EMAIL", "TELEFONE", "CELULAR"];
      const filterVal = ["nome", "email", "telefone", "celular"];
      const list = this.usuarios
        .filter((item) => !item.cadastro)
        .map((item) => {
          let user = {};
          user.nome = item.nome;
          user.email = item.email;
          user.telefone = item.telefone;
          user.celular = item.celular;

          return user;
        });

      const date = new Date();
      const now = format(date, "dd-MM-yyyy");
      const hrs = format(date, "HH");
      const mm = format(date, "mm");
      const ss = format(date, "ss");

      const filename = `Usuarios_nao_cadastrados_${now}_${hrs}hrs_${mm}m_${ss}s`;
      this.$Progress.start();
      this.loading = true;
      excelDownload(tHeader, filterVal, list, filename).then(() => {
        this.$Progress.finish();
        this.loading = false;
      });
    },

    getDispositivos() {
      this.loadingDispositivos = true;
      dispositivosUtilizados()
        .then((response) => {
          this.dispositivos = response;
          this.loadingDispositivos = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingDispositivos = false;
        });
    },

    toggleCadastro(item) {
      item.cadastro = item.cadastro
        ? null
        : format(new Date(), "yyyy-MM-dd HH:mm:ss");
      let usuario = {};
      usuario.cadastro = item.cadastro;
      this.upUsuario(item.id, usuario);
    },

    toggleCheckin(item) {
      item.checkin = item.checkin
        ? null
        : format(new Date(), "yyyy-MM-dd HH:mm:ss");
      let usuario = {};
      usuario.checkin = item.checkin;
      this.upUsuario(item.id, usuario);
    },

    toggleTes(item) {
      item.tes = item.tes ? false : true;
      let usuario = {};
      usuario.tes = item.tes;
      this.upUsuario(item.id, usuario);
    },

    toggleVip(item) {
      item.vip = item.vip ? false : true;
      let usuario = {};
      usuario.vip = item.vip;
      this.upUsuario(item.id, usuario);
    },

    toggleMaestria(item) {
      item.maestria = item.maestria ? false : true;
      let usuario = {};
      usuario.maestria = item.maestria;
      this.upUsuario(item.id, usuario);
    },

    toggleXDiamond(item) {
      item.xdiamond = item.xdiamond ? false : true;
      let usuario = {};
      usuario.xdiamond = item.xdiamond;
      this.upUsuario(item.id, usuario);
    },

    toggleDesativar(item) {
      item.status = item.status ? false : true;
      this.desativarUsuario(item.id);
    },

    upUsuario(id, usuario) {
      putUsuario(id, usuario)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Atualizado com sucesso");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    desativarUsuario(id) {
      deactivateUser(id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Atualizado com sucesso");
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getDispositivos();
  },
};
</script>

<style></style>
