import api from "../axios_service.js";

export async function all(filtros) {
  const response = await api.get(`/admin/usuarios${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function one(id) {
  const response = await api.get(`/admin/usuarios/${id}`);
  return response.data.result;
}

export function putUsuario(id, usuario) {
  return api.post(`/admin/usuarios/${id}`, usuario);
}

export function putUsuarioGeral(id, usuario) {
  return api.post(`/usuarios/${id}`, usuario);
}

export function postUsuario(usuario) {
  return api.post(`/admin/usuarios`, usuario);
}

export async function dispositivosUtilizados(filtros) {
  const response = await api.get(
    `/admin/usuarios-dispositivos${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export function postUsuarioSales(usuario) {
  return api.post(`/admin/usuarios-sales`, usuario);
}
export function postUsuarioSalesDuplicada(usuario) {
  return api.post(`/admin/usuarios-sales-duplicada`, usuario);
}

export async function allLeadsUsuarios(id) {
  const response = await api.get(`/admin/leads-staffs-participantes/${id}`);
return response.data.result;
}

export async function deactivateUser(id) {
  const response = await api.post(`/desativar-usuarios/${id}`);
  return response.data.result;
}
